import React from 'react'
import { Script } from 'gatsby'
import '../styles/globals.scss'
import { pageLayout } from './pageLayout.module.scss'
import Navbar from '../components/navbar'
import Footer from '../components/footer'

export default function PageLayout({ children }) {
  return (
    <div className={ pageLayout }>
      <header>
       <Navbar />
      </header>
      <main>
        {children}
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}